import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useModal } from '../../context/ModalContext'
import SettingsCell from '../SettingsCell'
import ManageUserSpaces from '../../modals/ManageUserSpaces'
import { roles, rolesDisplayMap } from '../../shared/enums'
import Avatar from '../Avatar'
import './index.css'
import { OrganizationRole } from '../../types'
import { useAuthContext } from '../../context/AuthContext'
import {
  getPersonStatus,
  personStatusIconMap
} from '../../shared/utilities'
import SortPeopleTags from '../SortPeopleTags'

interface PeopleTableProps {
  data: OrganizationRole[];
  tags: string[];
}

const PersonNameCell = ({ row }) => {
  const { currentUser } = useAuthContext()
  const navigate = useNavigate()
  const myPerson = currentUser?.organizationRoles?.find(
    (role) => role?.organization?.id === currentUser?.currentOrganizationId
  )

  const email = row.original.contact?.email || row.original.user?.email
  return (
    <div
      className="contact-details-cell"
      style={{ marginLeft: '5px' }}
      onClick={() =>
        navigate(`/communities/settings/people/${row.original?.id}`)
      }
      data-testid={`${row.original.contact?.firstName}-${row.original.contact?.lastName}-person-cell`}
    >
      <Avatar
        className="person-avatar"
        firstName={row.original.contact?.firstName}
        lastName={row.original.contact?.lastName}
        avatar={row.original.user?.avatar}
      />
      <div className="contact-details-container">
        <div className="contact-fullname">
          {row.original.contact?.firstName} {row.original.contact?.lastName}
          <span style={{ opacity: 0.6, marginLeft: '0.25rem' }}>
            {row.original?.id === myPerson?.id ? '(You)' : ''}
          </span>
        </div>
        {email && <div className="contact-email">{email}</div>}
      </div>
    </div>
  )
}

const PersonStatusCell = ({ row }) => {
  return (
    <div
      data-testid={`${row.original.contact?.firstName}-${row.original.contact?.lastName}-status-cell`}
      style={{
        minWidth: '11rem',
        display: 'flex',
        alignItems: 'center',
        gap: '0.25rem'
      }}
    >
      {personStatusIconMap[getPersonStatus(row.original)]}
      {getPersonStatus(row.original)}
    </div>
  )
}

const PersonRoleCell = ({ row }) => {
  return (
    <div
      style={{ minWidth: '7rem' }}
      data-testid={`${row.original.contact?.firstName}-${row.original.contact?.lastName}-role-cell`}
    >
      {row.original.primaryOwner
        ? `${rolesDisplayMap[row.original.title]} (Primary)`
        : rolesDisplayMap[row.original.title]}
    </div>
  )
}

const PersonSpacesCell = ({ row }) => {
  const { makeModal } = useModal()
  const personIsActive = row.original?.status === 'ACTIVE'

  if (!personIsActive) {
    return ''
  }

  return (
    <div
      data-testid={`${row.original.contact?.firstName}-${row.original.contact?.lastName}-spaces-cell`}
      className="member-spaces"
      onClick={() =>
        makeModal({
          modalSize: 'LARGE',
          modal: <ManageUserSpaces person={row.original} />
        })
      }
    >
      {row.original.spaceRoles?.length}
    </div>
  )
}

function PeopleTable ({ data, tags }: PeopleTableProps) {
  const [sortField, setSortField] = useState<string | null>(null)
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
  const [editPersonId, setEditPersonId] = useState<number | null>(null)
  const [selectedTags, setSelectedTags] = useState<string[]>([])
  const owners = data?.filter((person) => person?.title === roles.owner)
  const [searchTerm, setSearchTerm] = useState('')

  function makeFilteredData () {
    let filteredData = data

    if (searchTerm) {
      const searchLower = searchTerm.toLowerCase()
      filteredData = filteredData.filter((person) => {
        const fullName = `${person.contact?.firstName} ${person.contact?.lastName}`.toLowerCase()
        const email = (person.contact?.email || person.user?.email || '').toLowerCase()
        return fullName.includes(searchLower) || email.includes(searchLower)
      })
    }

    if (selectedTags.length > 0) {
      filteredData = filteredData.filter((person) => {
        const personTags = person.contact?.tags?.map(tag => tag.tag?.name) || []
        return selectedTags.some(tag => personTags.includes(tag))
      })
    }

    return filteredData
  }

  const personSelection = (value: number | null) => {
    setEditPersonId(value)
  }

  const sortData = (dataToSort: OrganizationRole[]) => {
    if (!sortField) return dataToSort

    return [...dataToSort].sort((a, b) => {
      const aValue = getFieldValue(a, sortField)
      const bValue = getFieldValue(b, sortField)

      const comparison = aValue > bValue ? 1 : aValue < bValue ? -1 : 0
      return sortDirection === 'asc' ? comparison : -comparison
    })
  }

  const getFieldValue = (row: OrganizationRole, field: string) => {
    switch (field) {
      case 'fullName':
        return `${row.contact?.firstName} ${row.contact?.lastName} ${row.contact?.email}`.toLowerCase()
      case 'status':
        return row.status
      case 'title':
        return row.title
      case 'spaces':
        return row.spaceRoles?.length || 0
      default:
        return ''
    }
  }

  const handleSort = (field: string) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortField(field)
      setSortDirection('asc')
    }
  }

  const handleTagSort = (tags: string[]) => {
    setSelectedTags(tags)
  }

  const filteredAndSortedData = sortData(makeFilteredData())

  return (
    <div className="people-table-container">
      <div className="table-controls" style={{ marginBottom: '1rem' }}>
        <SortPeopleTags
          tags={tags}
          handleSort={handleTagSort}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
        <input
          type="text"
          placeholder="Search by name or email"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          data-testid="people-search-field"
        />
      </div>
      <table>
        <thead>
          <tr>
            <th onClick={() => handleSort('fullName')}>
              Person {sortField === 'fullName' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('status')}>
              Status {sortField === 'status' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('title')}>
              Role {sortField === 'title' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th onClick={() => handleSort('spaces')}>
              Spaces {sortField === 'spaces' && (sortDirection === 'asc' ? '↑' : '↓')}
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredAndSortedData.map((row) => (
            <tr key={row.id}>
              <td>
                <div style={{
                  display: 'flex'
                }}>
                  <PersonNameCell row={{ original: row }} />
                </div>
              </td>
              <td><PersonStatusCell row={{ original: row }} /></td>
              <td><PersonRoleCell row={{ original: row }} /></td>
              <td><PersonSpacesCell row={{ original: row }} /></td>
              <td>
                <SettingsCell
                  person={row}
                  editPersonId={editPersonId}
                  personSelection={personSelection}
                  owners={owners}
                  setExpandPersonSettingsView={() => {}}
                  requiresExpandedSettingsDisplay={false}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default PeopleTable
